<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                海通证券携手非凸科技，开展算法推广研讨会
              </p>
              <div class="fst-italic mb-2">2023年1月12日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >研讨会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/27/01-研讨会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    1月12日，海通证券联合非凸科技在苏虹路营业部开展线下沙龙“e海通聚研讨会”，以客户需求为核心，发挥各自资源、技术、服务等优势，协同合作，持续创新，推动算法生态建设。
                  </p>
                   <p>
                    e海通聚是海通证券为专业投资者量身打造的专属交易平台，希望通过自主研发和外部合作的双轮驱动，可以更快地跟上专业客群的交易需求。此次合作，非凸科技将全力协助海通证券构建更便捷、高效、低成本的算法池，丰富算法库，提供优质服务。
                  </p>
                  <p>
                    非凸科技联合创始人&CEO王浚澎从算法策略、公司竞争力、产品服务、未来规划等维度进行了分享。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/27/02-研讨会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                   <h4 class="my-4">一、关于非凸</h4>
                  <p>
                    成立以来，非凸科技经历了多轮产品迭代和研发演进的过程。2021年，非凸科技采用Rust技术进行了底层重构，决定加速产品迭代升级，加快人才梯队建设。2022年，随着非凸算法新版本的上线，业绩稳居市场前列。截至目前，非凸科技服务了50余家机构客户，智能交易平台日均成交额已超60亿。
                  </p>
                  <p>
                    非凸科技注重区位优势，在总部上海建立策略中心，在成都和北京建立研发中心，在徐州自建超算中心提供强大算力，在美国和新加坡进行策略积累和人才储备。王浚澎表示，“非凸人员数量已超百人，发展速度快，在策略上具有丰富的海外履历经验，在技术上已是国内互联网一流水平，这也保证了我们长期的发展优势。”
                  </p>
                  <p>
                    当前，非凸科技已拥有30余项专利和软件著作权，并获得国家高新技术企业认证，另外每年投入千万级研发成本支持算力优化，融合机器学习、深度学习等人工智能技术支持交易技术研发，这为算法交易服务提供了软硬实力保障。
                  </p>
                  <h4 class="my-4">二、产品介绍</h4>
                  <p>
                    非凸智能算法是一款主动拆单算法，其优势在于：在多模型预测方面，非凸策略团队进行了单独模型开发，再通过模型中的方法来提升整体预测的准确性；在长周期预测方面，算法可以在5min-4h的交易任务中均表现出色；在算法迭代方面，每天迭代超10轮，且每轮速度很快，能够保证非凸算法及时适应市场变化；在绩效表现方面，非凸算法日均跑赢TWAP/VWAP基准3-5BP，基本领先市场上其他竞争伙伴。对此，王浚澎表示，“基于非凸算法的强大优势，以及优质的服务表现，增强了我们与客户的粘合度，获得了他们的高度认可，这也是我们值得自信和骄傲的地方。”
                  </p>
                   <p>
                    非凸T0算法，是非凸科技在2021年下半年重点打造的一个算法产品，目前条件成熟，正全面推向市场。其优势主要表现在预测能力强、信号预测准等方面，非凸科技拥有多套策略储备，鲁棒性强，可以根据不同底仓情况、市场变化，匹配不同子策略，除此之外，日内回撤小，平均持仓时间短，有很多敞口控制。
                  </p>
                   <p>
                    非凸智能交易系统，实际上是以上两款算法的载体，全部使用Rust语言进行基础开发，从而保证系统的稳定性。与此同时，非凸科技在延迟上也做了极致处理，并通过全内存通信及组播等技术进行延时优化。“我们的交易系统支持多账户管理，管理员可以集中在一个体系做统一交易；其次，内部策略有事前、事中风控，不同的风控策略保障了整体执行订单的合规性和安全性；然后，在交易过程中，有完善的交易界面，后台人员对交易异常情况进行实时监控、实时反馈，这也是我们要提升客户服务质量的一个重要部分；最后，针对客户接口，我们提供文件单、API等多种接入方式，保障算法交易顺利进行。”王浚澎补充道。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/27/03-研讨会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <h4 class="my-4">三、算法上线</h4>
                  <p>
                    目前，非凸科技已与中泰证券、中国银河证券、国泰君安证券、中金财富等多家券商达成总对总合作。王浚澎表示，“从上线券商反馈来看，非凸算法运行非常稳定，执行效率和超额收益也很有竞争力。当前算法预估容量在200亿左右，无论是券商还是管理人，都可以享受到现阶段的算法红利。”
                  </p>
                  <p>
                    现阶段，非凸科技正在推进海通证券的算法上线进展，测试环境测试已完成，预计春节后正式上线。双方将在算法策略、技术创新、产品服务、品牌推广等方面，围绕机构客户的业务需求，通力协作，携手共赢。
                  </p>
                  <p>
                    另外，三方渠道数据也显示出非凸算法的优异表现。自非凸算法上线各大券商以来，绩效表现明显优于其他厂商，平均超额在3.3bp左右。
                  </p>
                  <p>
                    除了产品迭代能力，非凸科技也始终在关注服务能力。王浚澎表示，“2023年是我们非常关键的一年，其中最关键的一步是如何继续提升服务质量，不论是券商，还是管理人，我们都要做到最优服务。”
                  </p>
                  <p>
                    2023年，非凸科技计划券商上线50家，服务管理人超200家，同时计划新产品上线，为更好服务合作伙伴务实基础。非凸科技将始终以技术之力，服务之本，为机构业务高质量发展赋能。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News26",
};
</script>

<style></style>
